import firebase from 'firebase';

const config = {
   apiKey: "AIzaSyA56gROm7RD1UpcI81cJaw1tN-Pbdf2AMk",
   authDomain: "public-key-da9b4.firebaseapp.com",
   databaseURL: "https://public-key-da9b4.firebaseio.com",
   projectId: "public-key-da9b4",
   storageBucket: "public-key-da9b4.appspot.com",
   messagingSenderId: "684409507310"
};
firebase.initializeApp(config);

export const auth = firebase.auth();
export const provider = new firebase.auth.GoogleAuthProvider();
//export const db = firebase.database();

 

export default firebase;
